<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-dialog v-model="dialog" persistent max-width="1450px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col cols="2" sm="4" md="3">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  New daily itiner
                </v-btn>
              </v-col>

              <v-col cols="10" sm="8" md="9">
                <v-radio-group class="float-right" v-model="filterOptions" row>
                  <v-radio label="New" :value="0"></v-radio>

                  <v-radio label="In progress" :value="5"></v-radio>

                  <v-radio label="Completed" :value="10"></v-radio>

                  <v-radio label="Closed" :value="15"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.vehicle"
                  :items="computedVehicleCollection"
                  label="Select vehicle"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>

              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.driver"
                  :items="computedDriverCollection"
                  label="Select driver"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.di"
                  :items="computedDICollection"
                  label="Select DI"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.site"
                  :items="computedSiteCollection"
                  label="Select site"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-card-title>
              <v-col cols="12" sm="12" md="12">
                <span class="headline">{{ formTitle }}</span>
                <v-btn
                  @click="handleCloseModalForm"
                  style="float: right; cursor: pointer"
                  icon
                  color="#26223c"
                >
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </v-col>
              <!-- v-if="statusLine" -->
              <v-col cols="12" sm="12" md="12">
                <v-tabs dark background-color="#232341" fixed-tabs>
                  <v-tabs-slider color="#232341"></v-tabs-slider>
                  <v-tab
                    v-for="(i, index) in statuses"
                    :key="index"
                    :href="'#tab-' + index"
                    :disabled="i != formModel.status"
                  >
                    <span style="color: #fff">{{
                      $t("ITINER.status_" + i)
                    }}</span>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="formValid" ref="form">
                  <v-row>
                    <v-col cols="6" sm="4" md="3">
                      <v-btn
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handlePrint"
                      >
                        Print
                      </v-btn>
                      <v-btn
                        v-if="formModel.status == 10"
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handleCloseItiner"
                      >
                        Close
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                      v-if="formModel.id && formModel.deletable"
                      cols="6"
                      sm="2"
                      md="2"
                    >
                      <v-btn
                        v-if="permissionCan('delete') && formModel.deletable"
                        depressed
                        color="error"
                        @click="handleDeleteItiner"
                      >
                        Delete itiner
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col cols="3" sm="3" md="3">
                      <label for="datepicker-full-width">Scheduled date</label
                      ><b-form-datepicker
                        id="datepicker-expiration_date"
                        v-model="formModel.scheduled_date"
                        :min="new Date(Date.now())"
                        calendar-width="100%"
                        locale="en"
                        class="mb-2"
                        style="max-width: 260px"
                        start-weekday="1"
                        required
                        @context="handleChangeScheduledDate"
                        :disabled="
                          formModel.id ||
                          formModel.addresses.length > 0 ||
                          formModel.status > 0
                        "
                      ></b-form-datepicker>
                      <small
                        v-if="messages['scheduled_date']"
                        style="color: red"
                      >
                        {{ messages["scheduled_date"] }}
                      </small>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-2">
                      <v-select
                        v-model="formModel.vehicle_id"
                        :items="computedVehicleSelectCollection"
                        label="Select vehicle"
                        item-value="id"
                        :id="dynamicID"
                        @change="handleChangeVehicleSelect"
                        :rules="requiredRules"
                        :disabled="formModel.id || formModel.status > 0"
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.name }} - {{ data.item.license_number }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.name }} - {{ data.item.license_number }}
                        </template>
                      </v-select>
                      <b
                        >Max weight:
                        <span v-if="formModel.vehicle_id"
                          >{{
                            computedVehicleSelectCollection.find(
                              (i) => i.id == formModel.vehicle_id
                            ).max_weight
                          }}
                        </span>
                      </b>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-2">
                      <v-select
                        v-model="formModel.user_id"
                        :items="driverSelectCollection"
                        label="Select driver"
                        item-text="name"
                        item-value="user_id"
                        :id="dynamicID"
                        :rules="requiredRules"
                        :disabled="formModel.id || formModel.status > 0"
                      />
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-2">
                      <v-select
                        v-model="formModel.site_id"
                        :items="siteCollection"
                        label="Related Site"
                        item-text="name"
                        item-value="id"
                        :id="dynamicID"
                        :rules="requiredRules"
                        :disabled="formModel.id || formModel.status > 0"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-divider></v-divider>
                <v-form
                  v-if="
                    formModel.scheduled_date &&
                    !formModel.id &&
                    !formModel.status > 0
                  "
                  v-model="formInputDataValid"
                  ref="formInputData"
                  class="mt-5"
                >
                  <v-row>
                    <v-col cols="2" sm="2" md="2" class="mt-3">
                      <v-autocomplete
                        v-model="inputData.transaction_number"
                        :items="transactionCollection"
                        item-text="transaction_number"
                        item-value="transaction_number"
                        label="Select transaction"
                        :rules="requiredRules"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-3">
                      <v-autocomplete
                        v-model="inputData.address"
                        :items="addressCollection"
                        label="Select address"
                        :rules="requiredRules"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2">
                      <div>
                        <label for="example-input">Deadline (time)</label>
                        <b-input-group>
                          <b-form-input
                            id="example-input"
                            v-model="inputData.deadline"
                            maxlength="5"
                            type="text"
                            placeholder="HH:mm"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-timepicker
                              button-only
                              right
                              locale="de"
                              aria-controls="example-input"
                              v-model="inputData.deadline"
                              v-bind="{
                                locale: 'en',
                                isRTL: false,
                                hourCycle: 'h23',
                                hour12: false,
                              }"
                            ></b-form-timepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-3">
                      <v-text-field
                        v-model="inputData.comment"
                        label="Comment"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" sm="1" md="1" class="mt-6">
                      <v-btn
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handleAddToItiner"
                        >Add to itiner</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-data-table
                      :headers="headers"
                      :items="formModel.addresses"
                      v-sortable-data-table
                      @sorted="saveOrder"
                      item-key="transaction_number"
                      class="mt-5"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <DeleteModalDialog
                            :dialogDelete="dialogDelete"
                            @closeDelete="handleCloseDelete"
                            @deleteItemConfirm="handleDeleteItemConfirm"
                          >
                          </DeleteModalDialog>
                          <v-row>
                            <v-col cols="1" sm="1" md="1">
                              <b>Destinations</b>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="3" sm="3" md="3">
                              <v-btn
                                class="button"
                                color="primary ml-8"
                                elevation="2"
                                small
                                v-if="formModel.id && computedStartAllShow"
                                @click="handleSaveStartAll"
                              >
                                Start all
                              </v-btn>
                              <v-btn
                                class="button"
                                color="primary ml-2"
                                elevation="2"
                                small
                                v-if="formModel.id && computedDeliveredAllShow"
                                @click="handleSaveDeliveredAll"
                              >
                                Delivered all
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.transaction_number="{ item }">
                        <router-link
                          :to="
                            urls[item.deliverable_type.replaceAll('\\', '')] +
                            item.deliverable_id
                          "
                          target="_blank"
                        >
                          {{ item.transaction_number }}
                        </router-link>
                      </template>
                      <template v-slot:item.weight="{ item }">
                        {{ item.weight }} kg
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <span v-if="formModel.id">
                          <v-icon color="primary" v-if="10 == item.status">
                            mdi-checkbox-marked
                          </v-icon>

                          <v-btn
                            v-if="5 == item.status"
                            color="primary"
                            small
                            @click="handleSaveDelivered(item)"
                          >
                            Delivered
                          </v-btn>

                          <v-btn
                            v-if="0 == item.status"
                            class="button ml-2"
                            color="primary"
                            elevation="2"
                            small
                            @click="handleSaveStart(item)"
                          >
                            Start
                          </v-btn>
                        </span>

                        <v-icon
                          small
                          @click="deleteItem(item)"
                          v-if="!formModel.id || 0 == formModel.status"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                    <small
                      v-if="!Array.isArray(messages['addresses'])"
                      style="color: red"
                    >
                      At least one destination is required!
                    </small>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="formModel.comment"
                      rows="5"
                      label="Comment"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="handleCloseModalForm">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="handleSaveModalForm">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
          <DeleteModalDialog
            :dialogDelete="dialogDeleteItiner"
            @closeDelete="handleCloseDeleteItiner"
            @deleteItemConfirm="handleDeleteItemConfirmItiner"
          >
          </DeleteModalDialog>
          <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <!-- <v-spacer></v-spacer> -->
            <v-spacer />
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :now="today"
            :type="type"
            :loader="loadingTable"
            @click:event="handleClickItem"
            @click:date="handleClickItem"
            @change="updateRange"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Sortable from "sortablejs";

import ApiService from "@/core/services/api.service";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import i18nService from "@/core/services/i18n.service.js";

const initialFormData = () => ({
  id: null,
  status: 0,
  vehicle_id: null,
  site_id: null,
  user_id: null,
  scheduled_date: null,
  comment: "",
  addresses: [],
});

const initialInputData = () => ({
  order: null,
  transaction_number: null,
  address: null,
  deadline: null,
  comment: null,
});

export const URL = {
  ModulesStockEntitiesTransfer: "/stock/transfers/",
  ModulesSalesEntitiesSalesOrder: "/sales/salesorders/",
  ModulesTransportEntitiesTransportOrder: "/transport/transportOrders/",
  ModulesManufactureEntitiesProject: "/manufacture/projects/",
  ModulesProcurementEntitiesPurchaseOrder: "/procurement/purchaseOrder/",
};

export default {
  components: { DeleteModalDialog },
  data: () => ({
    loader: false,
    urls: URL,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    statuses: [0, 5, 10, 15],
    selectedLocale: i18nService.languages.find((item) => {
      return item.lang == i18nService.getActiveLanguage();
    }),
    // statusLine: false,
    headers: [
      {
        text: "Order",
        align: "start",
        sortable: false,
        value: "order",
      },
      {
        text: "TO/PO ID",
        align: "start",
        sortable: false,
        value: "transaction_number",
      },
      {
        text: "Destination",
        align: "start",
        value: "address",
      },
      {
        text: "Dedline",
        align: "start",
        value: "deadline",
      },
      {
        text: "Comment",
        align: "start",
        sortable: false,
        value: "comment",
      },
      {
        text: "Weight",
        align: "start",
        sortable: false,
        value: "weight",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    requiredRules: [(v) => !!v || "Field is required"],
    permissions: JSON.parse(localStorage.permissions),
    permissionTo: "transport.itiner.",
    editedIndex: null,

    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    formModel: initialFormData(),
    dialog: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    dialogDelete: false,
    dialogDeleteItiner: false,
    editedItem: null,
    formValid: false,
    messages: Object.assign({}, initialFormData()),
    formInputDataValid: false,
    loadingTable: true,
    inputData: initialInputData(),
    transactionCollection: [],
    addressesCollection: [],
    vehicleCollection: [],
    driverSelectCollection: [],
    filterOptions: 0,
    selectOptions: {
      vehicle: { id: 0, name: "Select vehicle" },
      driver: { id: 0, name: "Select driver" },
      di: { id: 0, name: "Select DI" },
      site: { id: 0, name: "Select site" },
    },
  }),
  computed: {
    ...mapGetters(["itinerCollection", "siteCollection"]),

    formTitle() {
      return this.formModel.id
        ? "Daily itiner - " + this.formModel.di_number
        : "New daily itiner";
    },

    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },

    events() {
      this.itinerCollection.map((item) => {
        item.name = item.vehicle.license_number + " - " + item.vehicle.name;
        item.start = item.scheduled_date;
        item.end = item.scheduled_date;
        item.color = item.vehicle.color;
        item.timed = false;
        return item;
      });

      let filteredItinerCollection = this.itinerCollection.filter((item) => {
        let filtered = false;
        if (item.status == this.filterOptions) {
          filtered = true;

          if (
            this.selectOptions.vehicle.id > 0 &&
            this.selectOptions.vehicle.id != item.vehicle.id
          ) {
            filtered = false;
          }

          if (
            this.selectOptions.driver.id > 0 &&
            this.selectOptions.driver.id != item.user_id
          ) {
            filtered = false;
          }

          if (
            this.selectOptions.di.id > 0 &&
            this.selectOptions.di.id != item.id
          ) {
            filtered = false;
          }

          if (
            this.selectOptions.site.id > 0 &&
            this.selectOptions.site.id != item.site_id
          ) {
            filtered = false;
          }
        }

        return filtered;
      });

      return filteredItinerCollection;
    },

    addressCollection() {
      let addresses = [];

      this.siteCollection.forEach((item) => {
        addresses.push(
          item.country_id +
            "-" +
            item.contact_data[0].postcode +
            ", " +
            item.contact_data[0].city +
            ", " +
            item.contact_data[0].street
        );
      });

      if (this.inputData.transaction_number) {
        let transaction = this.transactionCollection.find(
          (item) => item.transaction_number == this.inputData.transaction_number
        );

        if (
          transaction &&
          transaction.destination &&
          transaction.destination.shipping_address
        ) {
          addresses.push(transaction.destination.shipping_address);
        }
        if (
          transaction &&
          transaction.source &&
          transaction.source.shipping_address
        ) {
          addresses.push(transaction.source.shipping_address);
        }
      }

      return addresses;
    },

    computedStartAllShow() {
      return (
        this.formModel.addresses.filter((i) => {
          return i.status == 0;
        }).length > 0
      );
    },

    computedDeliveredAllShow() {
      return (
        this.formModel.addresses.filter((i) => {
          return i.status == 5;
        }).length > 0
      );
    },

    computedVehicleCollection() {
      let vehicleCollection = [{ id: 0, name: "Select vehicle" }];

      this.events.forEach((item) => {
        let vehicle = item.vehicle;
        if (vehicleCollection.indexOf(vehicle) === -1) {
          vehicleCollection.push({ id: vehicle.id, name: vehicle.name });
        }
      });

      return vehicleCollection;
    },

    computedDICollection() {
      let diCollection = [{ id: 0, name: "Select DI" }];

      this.events.forEach((item) => {
        let di = { id: item.id, name: item.di_number };
        if (diCollection.indexOf(di) === -1) {
          diCollection.push(di);
        }
      });

      return diCollection;
    },

    computedDriverCollection() {
      let driverCollection = [{ id: 0, name: "Select Driver" }];
      console.log(this.events);
      this.events.forEach((item) => {
        let di = { id: item.user_id, name: item.user };
        if (driverCollection.indexOf(di) === -1) {
          driverCollection.push(di);
        }
      });

      return driverCollection;
    },

    computedSiteCollection() {
      let siteCollection = [{ id: 0, name: "Select site" }];

      this.events.forEach((item) => {
        let site = { id: item.site.id, name: item.site.name };
        if (siteCollection.indexOf(site) === -1) {
          siteCollection.push(site);
        }
      });

      return siteCollection;
    },

    computedVehicleSelectCollection() {
      let vehicleIds = this.itinerCollection
        .filter((i) => {
          if (
            i.scheduled_date == this.formModel.scheduled_date &&
            this.formModel.id &&
            this.formModel.vehicle_id == i.vehicle_id
          )
            return false;
          return i.scheduled_date == this.formModel.scheduled_date;
        })
        .map((i) => i.vehicle_id);

      return this.vehicleCollection.filter((i) => {
        return !vehicleIds.includes(i.id);
      });
    },
  },

  watch: {
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },

    "inputData.deadline": {
      handler: function (deadline) {
        if (deadline) {
          this.inputData.deadline = deadline.slice(0, 5);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchItiner", "fetchSite"]),

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    calculateDriverSelectCollection() {
      if (!this.itinerCollection) return [];

      let driverIds = this.itinerCollection
        .filter((i) => {
          if (
            i.scheduled_date == this.formModel.scheduled_date &&
            this.formModel.id &&
            this.formModel.user_id == i.user_id
          )
            return false;
          return i.scheduled_date == this.formModel.scheduled_date;
        })
        .map((i) => i.user_id);
      this.driverSelectCollection = this.userCollection.filter((i) => {
        return !driverIds.includes(i.id);
      });
    },

    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: this.formatDate(first, !allDay),
          end: this.formatDate(second, !allDay),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        });
      }

      this.start = start;
      this.end = end;
      this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },

    loadFormData(id) {
      return new Promise((resolve) => {
        this.loader = true;
        ApiService.get("transport/itiner/" + id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },

    editItem(id = null) {
      if (id) {
        this.loadFormData(id).then((data) => {
          this.userCollection = this.vehicleCollection
            .find((i) => i.id == data.vehicle.id)
            .users.map((item) => {
              item.user_id = item.id;
              return item;
            });

          this.formModel = Object.assign({}, data);
          this.calculateDriverSelectCollection();
          setTimeout(() => {
            this.dialog = true;
          }, 10);
        });
      }
    },

    fetchVecicles() {
      ApiService.get("transport/vehicle")
        .then(({ data }) => {
          this.vehicleCollection = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    fetchTransactions() {
      ApiService.get("transport/itiner/transactions")
        .then(({ data }) => {
          this.transactionCollection = data.filter((i) => {
            return (
              i.scheduled_date == this.formModel.scheduled_date ||
              i.type == "purchase_order"
            );
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleChangeVehicleSelect(vehicle) {
      this.userCollection = this.vehicleCollection
        .find((i) => i.id == vehicle)
        .users.map((item) => {
          item.user_id = item.id;
          return item;
        });

      this.calculateDriverSelectCollection();
    },

    handleAddToItiner() {
      this.$refs.formInputData.validate();
      if (this.formInputDataValid) {
        this.formModel.addresses.push(Object.assign({}, this.inputData));
        this.inputData = Object.assign({}, initialInputData());
        this.$refs.formInputData.resetValidation();
        this.setOrderToTable().then((ordered) => {
          this.formModel.addresses = ordered;
        });
      }
    },

    fetchModel() {
      return new Promise((resolve) => {
        this.fetchItiner();
        this.fetchVecicles();
        this.fetchSite();
        // this.fetchTransactions();
        resolve();
      });
    },

    setOrderToTable() {
      return new Promise((resolve) => {
        let ordered = this.formModel.addresses.map((item, index) => {
          item.order = index + 1;
          return item;
        });
        resolve(ordered);
      });
    },

    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.setOrderToTable().then((ordered) => {
          this.formModel.addresses = ordered;
          this.loader = true;
          if (!this.formModel.id) {
            ApiService.post("transport/itiner", this.formModel)
              .then(() => {
                this.handleCloseModalForm();
                this.$refs.form.resetValidation();
                this.fetchModel();
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                      console.log(field, errors[field][0]);
                    }
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
                console.log("Error!: ", error);
                // this.modalData.loading = false;
              })
              .finally(() => {
                this.loader = false;
              });
          } else {
            ApiService.put(
              "transport/itiner/" + this.formModel.id,
              this.formModel
            )
              .then(() => {
                this.handleCloseModalForm();
                this.$refs.form.resetValidation();
                this.fetchModel();
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                    }
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
                console.log("Error!: ", error);
                // this.modalData.loading = false;
              })
              .finally(() => {
                this.loader = false;
              });
          }
        });
      }
    },

    handleClickItem(item) {
      if (item.event && this.permissionCan("view")) {
        this.$router.push(`/transport/itiners/${item.event.id}`);
      } else {
        this.formModel.scheduled_date = item.date;
        setTimeout(() => (this.dialog = true), 10);
      }
    },

    handleCloseModalForm() {
      this.formModel = Object.assign({}, initialFormData());
      this.inputData = Object.assign({}, initialInputData());
      if (this.$refs.formInputData) this.$refs.formInputData.resetValidation();
      this.$refs.form.resetValidation();
      if (this.$route.name !== "itiners")
        this.$router.push({ name: "itiners" });
      this.resetErrorMessages();
      this.dialog = false;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.formModel.addresses.indexOf(item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      this.formModel.addresses.splice(this.editedIndex, 1);
      this.editedItem = null;
      this.editedIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItiner() {
      this.dialogDeleteItiner = true;
    },

    handleDeleteItemConfirmItiner() {
      ApiService.delete("transport/itiner/" + this.formModel.id).then(() => {
        this.fetchModel();
        this.dialogDeleteItiner = false;
        this.handleCloseModalForm();
      });

      this.dialogDelete = false;
    },

    handleCloseItiner() {
      ApiService.get(`transport/itiner/${this.formModel.id}/close`)
        .then(() => {
          this.editItem(this.formModel.id);
          this.fetchItiner();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveStart(item) {
      ApiService.get(`transport/itiner/${item.itiner_address_id}/start`)
        .then(() => {
          item.status = 5;
          this.editItem(this.formModel.id);
          this.fetchItiner();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveDelivered(item) {
      ApiService.get(`transport/itiner/${item.itiner_address_id}/deliver`)
        .then(() => {
          item.status = 10;
          this.editItem(this.formModel.id);
          this.fetchItiner();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveStartAll() {
      ApiService.get(`transport/itiner/${this.formModel.id}/startAll`)
        .then(() => {
          this.formModel.addresses
            .filter((i) => i.status == 0)
            .forEach((address) => {
              address.status = 5;
              return address;
            });
          this.editItem(this.formModel.id);
          this.fetchItiner();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveDeliveredAll() {
      ApiService.get(`transport/itiner/${this.formModel.id}/deliverAll`)
        .then(() => {
          this.formModel.addresses
            .filter((i) => i.status == 5)
            .forEach((address) => {
              address.status = 10;
              return address;
            });
          this.editItem(this.formModel.id);
          this.fetchItiner();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleChangeScheduledDate() {
      this.messages["scheduled_date"] = "";
      this.fetchTransactions();
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData());
      this.messages = errorData;
      // this.messages = {}
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    handlePrint() {
      window.open(
        process.env.VUE_APP_PORTAL_URL +
          "print/di/?di=" +
          this.formModel.hash +
          "&locale=" +
          this.selectedLocale.lang +
          "&print=1"
      );
    },

    saveOrder(event) {
      const movedItem = this.formModel.addresses.splice(event.oldIndex, 1)[0];
      this.formModel.addresses.splice(event.newIndex, 0, movedItem);
      this.setOrderToTable().then((ordered) => {
        this.formModel.addresses = ordered;
      });
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },

  async mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
    this.$refs.calendar.checkChange();
  },
};
</script>
